import React from 'react';
import {
   CallToAction,
   Layout,
   PageHeader,
   Seo,
   ServicesGrid,
} from '../../components';
import { graphql } from 'gatsby';

const Services = ({ data }) => {
   return (
      <Layout>
         <Seo title='Our Services' />
         <PageHeader
            title='Our Services'
            bgImg={data.titleImage.childImageSharp.gatsbyImageData}
         />
         <ServicesGrid services={data.datoCmsServicesPage.service} />
         <CallToAction
            address='/contact'
            description=''
            ctaTitle='Make enquires about our services'
            btnText='Enquire'
            dark='true'
         />
      </Layout>
   );
};

export const query = graphql`
   {
      titleImage: file(name: { eq: "bg-13" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
         }
      }
      datoCmsServicesPage {
         service {
            id
            serviceTitle
            serviceSlug
            serviceDesc
            serviceImage {
               gatsbyImageData(layout: FULL_WIDTH)
            }
         }
      }
   }
`;

export default Services;
